import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewStandardPageLayout } from '../../../layouts/newStadardPage.layout';
import {
  BigTitle,
  Bold,
  FlexCol,
  FlexWrapper,
  FlexWrapperStart,
  Img,
  SmallTitle,
  Text,
  Title,
  VozziButton,
  Wrapper,
} from '../../../global/new-global-components';
import {
  Content,
  ContentSection,
  HeroSection,
  NikanaPage,
  HeroSectionMobile,
  ArrangementSectionMobile,
  HeroSectionDesktop,
  PromoSectionDesktop,
  WhatsNikanaContainSection,
  PayExtraSection,
  NisSection,
  GiftSection,
  HowToBuySection,
  WhyVozziSection,
  HealthInsuranceSection,
  Heading,
  SubHeading,
  HeroBackground,
} from './nikana.styles';
import { Link } from 'gatsby';
import { LanguageGuard } from '../../../components/layout/LanguageGuard/LanguageGuard';
import { navigateTo } from '../../../components/functional/Navigate';
import { ResponsiveContainer } from '../../../components/functional/ResponsiveContainer.functional';
import { COUNTRY } from '../../../configs/env';

const nikanaNewLandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/Nikana-OG-img-b.jpg`;

  const meta_: any = {
    rs: {
      title: 'Sa Nikanom VOZZi u Grčku',
      description:
        'I ove godine, VOZZi i Nikana su ti pripremili paket pomoći na putu koji ti omogućava bezbrižnu i sigurnu vožnju do Grčke. Predstavljamo ti Nikana Travel Greece paket - pojačan i unapređen sa pokrivenim troškovima popravke do 400EUR u vreme trajanja tvog aranžmana na teritoriji Grčke bukiranom preko Nikane.',
    },
    mk: {
      title: 'Со Nikanа VOZZi во Грција',
      description:
        'VOZZi и Никана подготвија пакет помош на патот кој ти овозможува безгрижно и безбедно возење до Грција. Tи го претставуваме пакетот Nikana Travel Greece paket - засилен и надграден со покриени трошоци за поправка до 400 ЕУР за време на траење на твојот аранжман на територијата на Грција букиран преку Никана.',
    },
  };

  const meta = meta_[COUNTRY];

  const og = {
    image: metaOgImg,
  };

  const activationLink = 'https://link.vozzi.app/nikana-travel-leto-2023';
  const qrImageUrl = '/new-images/nikana-travel-leto-2023-QR.png';

  return (
    <NewStandardPageLayout
      nav={false}
      footerTopSection={false}
      fullWidth={true}
      mainHeading={t('career_MainHeading')}
      backgroundColor="white"
      backgroundImage="bg/career@3x.jpg"
      backgroundImageMobile="bg/career-mobile@3x.jpg"
      og={og}
      meta={meta}
    >
      <NikanaPage>
        <HeroSectionMobile>
          <div className="cloud"></div>
          <div className="cloud-1"></div>
          <Img className="sun" width="350px" style={{ position: 'absolute', right: '100px', top: '0px' }} src="/new-images/sun.png" />

          <div className="logo-section">
            <a href={`/${LANG}`}>
              <img className="logo-vozzi" src="/new-images/logo-white.svg" />
            </a>
            <a href="https://nikana.gr/">
              <img className="logo-nikana" src="/new-images/nikana.svg" />
            </a>
          </div>

          {LANG === 'sr' ? (
            <>
              <h1>
                <span>Sa Nikanom</span> VOZZi u Grčku
              </h1>

              <ul>
                <li>
                  <b>400 EUR</b> za popravku na teritoriji Grčke
                </li>
                <li>
                  <b>200 EUR</b> popravke u Severnoj Makedoniji i Bugarskoj
                </li>
                <li>
                  <b>Cash back</b> na <b>SNNP</b> kartici
                </li>
                <li>
                  <b>2 poklon paketa VOZZi 40</b>
                </li>
                <li>Doplata za celu Evropu za 500 RSD</li>
              </ul>
            </>
          ) : (
            <>
              <h1>
                <span>Со Nikanа</span> VOZZi во Грција
              </h1>

              <ul>
                <li>
                  <b>400 EUR</b> поправка во Грција за време на траење на аранжманот резервиран букиран преку Nikana.gr
                </li>
                <li>
                  <b>200 EUR</b> во Грција, надвор од аранжманот букиран преку Nikana.gr
                </li>
                <li>
                  <b>2 пакети за подарок VOZZi 40</b>
                </li>
                <li>Доплата за цела Европа за само 933 ДЕН</li>
              </ul>
            </>
          )}

          <img src="/new-images/nikana-telefon-min.png" alt="" />

          <div>
            <div className="promo-and-activation">
              <img
                src={LANG === 'sr' ? '/new-images/promo-ponuda.svg' : '/new-images/promo-ponuda-mk.svg'}
                className="promo-image"
                alt=""
              />
              <button onClick={() => navigateTo(activationLink)}>
                {LANG === 'sr' ? 'Aktiviraj Nikana paket' : 'Aktivirajte go paketot Nikana'}
              </button>
            </div>

            <p className="orange">{LANG === 'sr' ? 'Ponuda traje do kraja leta 2023.' : 'Понуда трае до крајот летото, 2023 година.'}</p>
          </div>
        </HeroSectionMobile>

        <ArrangementSectionMobile>
          {LANG === 'sr' ? (
            <>
              <h2>Dok aranžman traje, sigurnost na putu je veća.</h2>
              <h3>Putuješ u Grčku sa Nikanom?</h3>

              <p>
                I ove godine, VOZZi i Nikana su ti pripremili paket pomoći na putu koji ti omogućava bezbrižnu i sigurnu vožnju do Grčke.
                Predstavljamo ti <b>Nikana Travel Greece paket</b> - pojačan i unapređen sa pokrivenim troškovima popravke do 400EUR u vreme
                trajanja tvog aranžmana na teritoriji Grčke bukiranom preko Nikane. Kupovinom paketa ostvaruješ pravo i na 10% cash back u
                vidu bonus poena na tvojoj SNNP kartici.
              </p>
            </>
          ) : (
            <>
              <h2>Додека трае аранжманот, безбедноста на патиштата е поголема.</h2>
              <p>
                VOZZi и Никана подготвија пакет помош на патот кој ти овозможува безгрижно и безбедно возење до Грција. Tи го претставуваме
                пакетот Nikana Travel Greece paket - засилен и надграден со покриени трошоци за поправка до 400 ЕУР за време на траење на
                твојот аранжман на територијата на Грција букиран преку Никана.
              </p>
            </>
          )}
        </ArrangementSectionMobile>

        <HeroBackground>
          <HeroSectionDesktop>
            <div className="cloud"></div>
            <div className="cloud-1"></div>
            <Img className="sun" width="350px" style={{ position: 'absolute', right: '100px', top: '0px' }} src="/new-images/sun.png" />

            <div className="logo-section">
              <a href={`/${LANG}`}>
                <img className="logo-vozzi" src="/new-images/logo-white.svg" />
              </a>
              <a href="https://nikana.gr/">
                <img className="logo-nikana" src="/new-images/nikana.svg" />
              </a>
            </div>

            <div className="hero-section">
              <div className="heading">
                {LANG === 'sr' ? (
                  <>
                    <h1>
                      <span>Sa Nikanom</span> VOZZi u Grčku
                    </h1>

                    <h2>Dok aranžman traje, sigurnost na putu je veća</h2>

                    <h3>Putuješ u Grčku sa Nikanom?</h3>

                    <p>
                      I ove godine, VOZZi i Nikana su ti pripremili paket pomoći na putu koji ti omogućava bezbrižnu i sigurnu vožnju do
                      Grčke. Predstavljamo ti Nikana Travel Greece paket - pojačan i unapređen sa pokrivenim troškovima popravke do 400EUR u
                      vreme trajanja tvog aranžmana na teritoriji Grčke bukiranom preko Nikane. Kupovinom paketa ostvaruješ pravo i na 10%
                      cash back u vidu bonus poena na tvojoj SNNP kartici.
                    </p>
                  </>
                ) : (
                  <>
                    <h1>
                      <span>Со Nikanа</span>
                      <br /> VOZZi во Грција
                    </h1>

                    <h2>Додека трае аранжманот, безбедноста на патиштата е поголема.</h2>

                    <h3>Патувате во Грција со Nikanа?</h3>

                    <p>
                      VOZZi и Никана подготвија пакет помош на патот кој ти овозможува безгрижно и безбедно возење до Грција. Tи го
                      претставуваме пакетот Nikana Travel Greece paket - засилен и надграден со покриени трошоци за поправка до 400 ЕУР за
                      време на траење на твојот аранжман на територијата на Грција букиран преку Никана.
                    </p>
                  </>
                )}
              </div>
              <img src="/new-images/nikana-telefon-min.png" alt="" />
            </div>
          </HeroSectionDesktop>
        </HeroBackground>

        <PromoSectionDesktop>
          <img src={LANG === 'sr' ? '/new-images/promo-ponuda.svg' : '/new-images/promo-ponuda-mk.svg'} className="promo-ponuda" alt="" />

          <h2>
            {LANG === 'sr' ? 'Promotivna ponuda traje do kraja leta 2023.' : 'Промотивната понуда трае до крајот на април, 2023 година.'}
          </h2>

          <ul>
            <li>
              <b>400 {LANG === 'sr' ? 'EUR' : 'евра'}</b>
              {LANG === 'sr'
                ? ' za popravku na teritoriji Grčke'
                : ' поправка во Грција за време на траење на аранжманот резервиран букиран преку Nikana.gr'}
            </li>
            <li>
              <b>200 {LANG === 'sr' ? 'EUR' : 'евра'}</b>
              {LANG === 'sr' ? ' popravke u Severnoj Makedoniji i Bugarskoj' : ' во Грција, надвор од аранжманот букиран преку Nikana.gr'}
            </li>
            {LANG === 'sr' && (
              <li>
                <b>Cash back</b> na <b>SNNP</b> kartici
              </li>
            )}

            <li>
              <b>{LANG === 'sr' ? '2 poklon paketa VOZZi 40' : '2 пакети за подарок VOZZi 40'}</b>
            </li>
            <li>{LANG === 'sr' ? 'Doplata za celu Evropu za 500 RSD' : 'Доплата за цела Европа за само 933 ДЕН'}</li>
          </ul>

          <button onClick={() => navigateTo(activationLink)}>Aktiviraj Nikana paket</button>
        </PromoSectionDesktop>

        <WhatsNikanaContainSection>
          {LANG === 'sr' ? (
            <>
              <h2>Šta sve sadrži Nikana Travel Greece paket?</h2>
              <h3>Usluge u paketu:</h3>

              <ul>
                <li>Brza intervencija da odmah nastaviš svoj put</li>
                <li>Kada brza intervencija ne rešava problem, šlepamo te do našeg servisa</li>
                <li>
                  U servisu ti pokrivamo{' '}
                  <b>popravku do 400€ U TRAJANJU aranžmana u Grčkoj bukiranom preko Nikana.gr, a na teritoriji Grčke</b>
                </li>
                <li>U servisu ti pokrivamo popravku do 200€, van aranžmana u Grčkoj bukiranom preko Nikana.gr </li>
                <li>Dok popravka traje, ti i putnici odmarate u hotelu</li>
                <li>Kada popravka nije moguća ili traje predugo, šlepamo auto i vraćamo putnike do kuće bez ikakvih doplata</li>
                <li>Ko želi dalje, VOZZi pokriva troškove putovanja</li>
              </ul>

              <h3>Usluge na teritoriji Srbije:</h3>

              <ul>
                <li>Brza intervencija da odmah nastaviš svoj put (x2)</li>
                <li>Tokom popravke, tvoj auto čuvamo na našem parkingu (x2)</li>
                <li>Dok popravka traje ti i putnici odmarate u hotelu</li>
                <li>Kada brza intervencija ne pomaže, auto i putnike šlepamo do kuće bez doplate</li>
                <li>Tu je i jedan dodatni šlep sa pokrićem do 40km</li>
              </ul>

              <h3 style={{ marginTop: '30px' }}>Teritorijalna pokrivenost:</h3>
              <p>Srbija, Severna Makedonija, Bugarska i Grčka, bez ikakvih dodatnih troškova.</p>

              <h2>
                Period trajanja: <span>12 meseci</span>
              </h2>
              <h2>
                Cena: <span>4.999RSD</span>
              </h2>
            </>
          ) : (
            <>
              <h2>Што се содржи пакетот Nikana Travel Greece?</h2>
              <h3>Услуги во пакетот:</h3>

              <ul>
                <li>Брза поправка за веднаш да го наставиш твојот пат</li>
                <li>Кога брзата поправка не го реши проблемот, те шлепаме до нашиот сервисен центар</li>
                <li>
                  Во сервисот покриваме поправка до 400€ ВО ТРАЕЊЕ на аранжманот во Грција резервиран преку Nikana.gr и на територијата на
                  Грција
                </li>
                <li>Во сервисот покриваме поправка до 200€, надвор од аранжмани во Грција резервирани преку Nikana.gr</li>
                <li>Додека се врши поправката, ти и патниците одмарате во хотелот</li>
                <li>Го шлепаме автомобилот без ограничувања и те возиме дома</li>
                <li>Доколку сакаш да наставиш патот понатаму, VOZZi ги покрива патните трошоци</li>
              </ul>

              <h2>Услуги на територијата на Македонија:</h2>

              <ul>
                <li>Брза поправка за да го продолжиш патувањето веднаш (x2)</li>
                <li>Има и еден шлеп до 40 км</li>
                <li>Кога брзото поправање не помага, ги влечеме автомобилот и патниците дома</li>
                <li>Додека поправката е во тек, ти и патниците одмарате во хотелот</li>
                <li>Го чуваме твојот автомобил на нашиот паркинг (x2)</li>
              </ul>

              <h2>Територијална покриеност:</h2>
              <p>Северна Македонија и Грција</p>

              <h2>
                Време траење: <span>12 месеци</span>
              </h2>
              <h2>
                Цена: <span>2.466 ДЕН</span>
              </h2>
            </>
          )}
        </WhatsNikanaContainSection>

        <PayExtraSection>
          {LANG === 'sr' ? (
            <>
              <h2>Za samo 500 RSD, doplati za celu Evropu</h2>
              <p>Nikana Travel Greece paket lako možeš nadograditi na VOZZi Evropa paket.</p>
            </>
          ) : (
            <>
              <h2>За само 933 ДЕН, доплати за целата Европа.</h2>
              <p>Пакетот Nikana Travel Greece можеш лесно да го надградиш на пакетот VOZZi Evropa.</p>
            </>
          )}
          <img src="/new-images/upgrade-kartice.svg" alt="" />
        </PayExtraSection>

        {LANG === 'sr' && (
          <NisSection className="section" style={{ backgroundImage: 'url("/new-images/nis-section-background.png")' }}>
            <Wrapper>
              <>
                <h2>Ostvari cash back od 10% na SNNP kartici kupovinom Nikana Travel Greece paketa</h2>
                <p>Bonus poene možeš iskoristiti za umanjenje računa prilikom kupovine na NIS Petrol i Gazprom benzinskim stanicama.</p>
              </>
            </Wrapper>
            <Img style={{}} src="/new-images/nis-kartica.png" />
          </NisSection>
        )}

        {LANG === 'sr' && (
          <GiftSection>
            <img src="/new-images/paketici.svg" alt="" />

            <p>
              Kada kupiš <b>Nikana Travel Greece paket dobijaš dva VOZZi 40 vaučera u vrednosti od po 499RSD</b> koja možeš nekome
              pokloniti.
            </p>
          </GiftSection>
        )}

        <HowToBuySection>
          {LANG === 'sr' ? (
            <>
              <h2>Kako do paketa?</h2>

              <div className="qr-and-button-wrap">
                <img className="qr" src={qrImageUrl} alt="" />
                <button onClick={() => navigateTo(activationLink)}>Aktiviraj Nikana paket</button>
              </div>

              <div className="steps" style={{ marginTop: '70px' }}>
                <h3>1.</h3>
                <p>
                  Putem tvog mobilnog telefona, skeniranjem QR koda ili klikom na link bićeš preusmeren/a na instalaciju VOZZi aplikacije,
                  ili će ti se aplikacija sama pokrenuti ukoliko je već imaš instaliranu.
                </p>
              </div>
              <img src="/new-images/nikana-ekran-1.png" className="step" alt="" />

              <div className="steps">
                <h3>2.</h3>
                <p>
                  Nakon što se registruješ, u ponudi ćeš videti Nikana Travel Greece paket, koji je kreiran samo za ovu priliku i vidljiv
                  isključivo putem prethodno navedenog linka ili QR koda. Kupovinu Nikana Travel Greece VOZZi paketa pomoći na putu možeš
                  obaviti isključivo putem aplikacije.
                </p>
              </div>
              <img src="/new-images/nikana-sat.png" className="step" alt="" />

              <div className="steps">
                <h3>3.</h3>
                <p>Nakon perioda aktivacije od 72 sata možeš da koristiš sve pogodnosti Nikana Travel Greece paketa pomoći na putu.</p>
              </div>
            </>
          ) : (
            <>
              <h2>Kако до пакетот?</h2>

              <div className="qr-and-button-wrap">
                <img className="qr" src={qrImageUrl} alt="" />
                <button onClick={() => navigateTo(activationLink)}>Активирај го пакетот Никана</button>
              </div>

              <div className="steps" style={{ marginTop: '70px' }}>
                <h3>1.</h3>
                <p>
                  Со користење на твојот мобилен телефон, скенирање на QR-кодот или кликнување на врската, ќе бидеш пренасочен кон
                  инсталацијата на апликацијата VOZZi или апликацијата ќе започне автоматски ако веќе ја имаш инсталирано.
                </p>
              </div>
              <img src="/new-images/nikana-ekran-1.png" className="step" alt="" />

              <div className="steps">
                <h3>2.</h3>
                <p>
                  Откако ќе се регистрираш, во понудата ќе ги видиш пакетите Никана кои се креирани токму за оваа прилика и се видливи само
                  преку споменатиот линк или QR код. Купување на пакетот за помош на пат Nikana Travel Greece VOZZi можете да го направете
                  исклучиво преку апликацијата.
                </p>
              </div>
              <img src="/new-images/nikana-sat.png" className="step" alt="" />

              <div className="steps">
                <h3>3.</h3>
                <p>
                  По истекот на периодот на активирање од 72 часа, можеш да ги искористиш сите поволности на пакетот за помош на пат на
                  Nikana Travel Greece.
                </p>
              </div>
            </>
          )}
        </HowToBuySection>

        {LANG === 'mk' && (
          <GiftSection>
            <img src="/new-images/paketici.svg" alt="" />

            <p>
              Кога купувате пакет <b>Nikana Travel Greece, добивате два ваучери VOZZi 40 во вредност од по 250 ДЕН</b>, кои можете да ги
              подарите некому.
            </p>
          </GiftSection>
        )}

        <WhyVozziSection>
          {LANG === 'sr' ? (
            <>
              <h2>Zašto VOZZi?</h2>
              <p>VOZZi je prva potpuno digitalizovana pomoć na putu.</p>
              <p>
                Dovoljno je samo da klikneš dugme u aplikaciji i pomoć stiže do tebe. Nema bespotrebnog čekanja na linijama sa call centrom,
                objašnjavanja lokacije, nema nervoze i stresa. VOZZi precizno pronalazi tvoju lokaciju, šlepamo te do prvog partnerskog
                servisa i dijagnostikujemo problem. Naša mreža partnera se prostire širom Evrope i broji preko 3000 servisa koji su obučeni
                i specijalizovani za pružanje pomoći na putu. U dobrim si rukama!
              </p>
              <p>
                Mi smo idealni saputnik svakom vozaču, na svakom putovanju. Samo u protekloj godini VOZZi je realizovao na hiljade uspešnih
                asistencija.
              </p>

              <ResponsiveContainer breakpoint="sm">
                {(matches: any): JSX.Element => (
                  <>
                    {matches.mobile && <Img width="100%" margin="40px 0" src="/new-images/iskustva-mobile.png" />}
                    {matches.desktop && <Img width="100%" margin="40px 0" src="/new-images/iskustva.png" />}
                  </>
                )}
              </ResponsiveContainer>

              <p className="last-paragraph">
                Ne dozvoli da ti bilo šta pokvari odmor. Ne dozvoli da satima čekaš pomoć na suncu, u vrelim letnjim danima. Opušteno putuj
                na svoju omiljenu destinaciju u Grčkoj sa Nikanom i VOZZi u tvom telefonu.
              </p>
            </>
          ) : (
            <>
              <h2>Зошто VOZZI?</h2>
              <p>VOZZi е првата целосно дигитализирана помош на пат.</p>
              <p>
                Доволно е да кликнеш на едно копче во апликацијата и ќе ти дојде помош. Без непотребно чекање на редови на кол центар, без
                објаснување за локацијата, без нервоза и стрес. VOZZi прецизно ја наоѓа твојата локација, те шлепаме до првиот партнер
                сервис и го дијагностицираме проблемот. Нашата мрежа на партнери се протега низ Европа и брои над 3.000 услуги кои се
                обучени и специјализирани за давање помош на патиштата. Во добрите раце си!
              </p>
              <p>
                Ние сме идеален придружник за секој возач, на секое патување. Само во изминатата година ВОЗЗи реализираше илјадници успешни
                асистенции.
              </p>
              <Img width="100%" margin="40px 0" src="/img/customer-satisfaction--vozzi.jpg" />
              <p className="last-paragraph">
                Не дозволувај ништо да ти го расипе одморот. Не дозволувај да чекаш со часови на сонце за помош во топлите летни денови.
                Опушти се и патувај до омилената дестинација во Грција со Nikana и VOZZi во телефонот.
              </p>
            </>
          )}
        </WhyVozziSection>

        {LANG === 'sr' && (
          <div style={{ background: '#eeeeee' }}>
            <HealthInsuranceSection>
              <img src="/new-images/grawe.png" alt="" />

              <h3>I to nije sve!</h3>
              <h2>Putno zdravstveno osiguranje samo za VOZZi korisnike na 12 meseci</h2>
              <p>
                VOZZi u saradnji sa Grawe osiguravajućom kućom nudi godišnje putno zdravstveno osiguranje, sa neograničenim brojem ulazaka i
                izlazaka iz zemlje, a koje takođe možete kupiti unutar aplikacije u svega nekoliko koraka. Dakle, plaćate samo jednom, a
                svaki Vaš odmor do 15 dana je pokriven u potpunosti. Ma koliko puta išli u inostranstvo, uvek ste pokriveni.
              </p>
            </HealthInsuranceSection>
          </div>
        )}
      </NikanaPage>
    </NewStandardPageLayout>
  );
};

export default nikanaNewLandingPage;
